import React, { useRef, useState } from "react";
import { Link } from "gatsby"

import { Swiper, SwiperSlide } from "swiper/react";

import { navigate } from 'gatsby';




import Updates from './updates.js';





import "swiper/css";

import {Navigation } from "swiper";

export default function App(props) {

  






  return (

      <>
  <Swiper
        className='transparent'
        spaceBetween={0}

                centeredSlides={true}
        resistance={false}  



        grabCursor={false}
  


initialSlide={1}
  onSlideChange={(swiperCore) => {
            const {
              activeIndex,
              snapIndex,
              previousIndex,
              realIndex,
            } = swiperCore;

window.scrollTo(0,0)


              if (swiperCore.realIndex === 2) {    navigate('/books');    };

              if (swiperCore.realIndex === 0) {    navigate('/');    };





        }}



        pagination={{
          clickable: true,
        }}
      >



              <SwiperSlide  className=' transparent'> </SwiperSlide>





            
             <SwiperSlide  className='switchToSLC profiles white'> 




<div className='longPage '>





<div className='imgContainer'>
<img className=' animate__animated' src='/images/EugenOvidiuChirovici.avif' />
</div>

<span className='standardIntroductionText animate__animated '>


<div className='aboutHeadlineBox'>
<span className='aboutHeadline'>


E.O. Chirovici is an internationally bestselling author, known primarily for The Book of Mirrors, which is one of the most widely translated books currenty in print and the basis for the film Sleeping Dogs.    


</span>
</div>



<p className='animate__animated'>

Born in Transylvania in a Romanian — Hungarian — German family, I made my literary debut with a collection of short stories and my first novel, The Massacre, sold over 100,000 copies. My writing spans both fiction and non-fiction, primarily economics, history, and international affairs.

</p>



<p className='animate__animated'>

After graduating the Romanian Academy of Economics, I embarked on a professional career as a journalist, first running a daily newspaper and then a television station. I've also written several works of non-fiction, including <Link rel='nofollow' title='Rumours' to='/books/rumours'>Rumors</Link> That Changed the World: A History of Violence and Discrimination (Rowman and Littlefield, US, 2014) and <Link rel='nofollow' title='Gods Weapons and Money' to='/books/gods-weapons-and-money'>Gods, Weapons & Money</Link>: The Puzzle of Power (Nortia Press, US, 2014). I currently hold three PhDs, in Communication, Economics, and History. 

<br/><br/>

My first novel in English, <Link rel='nofollow' title='The Book of Mirrors' to='/books/book-of-mirrors'>The Book of Mirrors</Link> (Atria - Century, 2017 / 2018) is an international bestseller and has been translated into over 39 languages. The film adapatation, <a title='Sleeping Dogs Film' target='_blank' href='https://www.imdb.com/title/tt8542964/'>Sleeping Dogs</a>, is currently in production by Nickel City Pictures while my second novel <Link rel='nofollow' title='Bad Blood' to='/books/bad-blood'>Bad Blood</Link> has been published in over a dozen countries.

</p>



<div id='updates'>

<h4>Updates</h4>


<Updates/>

</div>





</span>




















</div>

        </SwiperSlide>





























      <SwiperSlide className=''> </SwiperSlide>

</Swiper>
       </> 
            
  );
}